<template>
  <div class="bg-white">
    <div v-show="isLoading">
      <OtherLoading />
    </div>
    <div v-show="!isLoading" class="p-3 pre-box title-panel">
      <b-row>
        <b-col sm="3 border-right">
          <label>All Redeem</label>
          <div>
            <span class="text-score"
              >{{ data.totalRedeem | numeral("0,0") }}
            </span>
            <span>Time(s)</span>
          </div>
        </b-col>
        <b-col sm="3 border-right">
          <label>In Process</label>
          <div>
            <span class="text-score"
              >{{ data.inProgress | numeral("0,0") }}
            </span>
            <span>Time(s)</span>
          </div>
        </b-col>
        <b-col sm="3 border-right">
          <label>Succeed</label>
          <div>
            <span class="text-score">{{ data.success | numeral("0,0") }} </span>
            <span>Time(s)</span>
          </div>
        </b-col>
        <b-col sm="3">
          <label>Cancel/Expire</label>
          <div>
            <span class="text-score"
              >{{ data.cancelOrExpire | numeral("0,0") }}
            </span>
            <span>Time(s)</span>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-show="!isLoading" class="pl-3 pr-3 pt-3">
      <b-input-group>
        <b-form-input
          class="search-bar"
          @keyup.enter="getRedeemOrderList"
          placeholder="Search Name, Member ID, Tel., Email"
          v-model="filter.search"
        >
        </b-form-input>
        <b-input-group-append is-text class="mr-2">
          <b-iconstack font-scale="2" type="submit" @click="getRedeemOrderList">
            <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
          </b-iconstack>
        </b-input-group-append>
        <b-button v-b-toggle.sidebar-filter class="btn button btn-mobile px-4">
          <font-awesome-icon
            icon="filter"
            title="filter-btn"
            class="main-color mr-0 mr-sm-1"
          />
          <span class="d-none d-sm-inline">Filter</span>
        </b-button>
        <b-dropdown text="Options" class="ml-2 main-color btn-filter">
          <b-dropdown-item @click="confirmAction(1)" :disabled="actionDisabled"
            ><span :class="actionDisabled ? 'text-gray' : 'text-black'"
              >รอจัดเตรียมสินค้า</span
            ></b-dropdown-item
          >
          <b-dropdown-item @click="confirmAction(2)" :disabled="actionDisabled"
            ><span :class="actionDisabled ? 'text-gray' : 'text-black'"
              >กำลังจัดเตรียม</span
            ></b-dropdown-item
          >
          <b-dropdown-item @click="confirmAction(3)" :disabled="actionDisabled"
            ><span :class="actionDisabled ? 'text-gray' : 'text-black'"
              >สินค้าพร้อมรับ</span
            ></b-dropdown-item
          >
          <b-dropdown-item @click="confirmAction(4)" :disabled="actionDisabled"
            ><span :class="actionDisabled ? 'text-gray' : 'text-black'"
              >รับของโดยไม่ได้รับ OTP</span
            ></b-dropdown-item
          >
          <b-dropdown-item
            @click="confirmAction(5)"
            :disabled="actionDisabled && !onlyAction"
            ><span
              :class="
                actionDisabled && !onlyAction ? 'text-gray' : 'text-black'
              "
              >รับของแล้ว</span
            ></b-dropdown-item
          >
          <b-dropdown-item @click="confirmAction(6)" :disabled="actionDisabled"
            ><span :class="actionDisabled ? 'text-gray' : 'text-black'"
              >ยกเลิก</span
            ></b-dropdown-item
          >
          <b-dropdown-item @click="confirmAction(7)" :disabled="actionDisabled"
            ><span :class="actionDisabled ? 'text-gray' : 'text-black'"
              >หมดอายุ</span
            ></b-dropdown-item
          >
          <b-dropdown-item @click="confirmAction(8)" :disabled="actionDisabled"
            ><span :class="actionDisabled ? 'text-gray' : 'text-black'"
              >ส่งสำเร็จ</span
            ></b-dropdown-item
          >
        </b-dropdown>
        <button
          type="button"
          class="btn button btn-mobile px-4 ml-2"
          @click="exportData"
          :disabled="exportDisabled"
        >
          <font-awesome-icon icon="plus" class="text-black d-sm-none" />
          <span class="d-none d-sm-block">
            <font-awesome-icon icon="file-upload" class="pointer" />
            Export
          </span>
        </button>
        <button
          type="button"
          class="btn button btn-mobile px-4 ml-2"
          @click="importData"
        >
          <font-awesome-icon icon="file-import" class="text-black d-sm-none" />
          <span class="d-none d-sm-block">
            <font-awesome-icon icon="file-import" class="pointer" />
            Import
          </span>
        </button>
      </b-input-group>
    </div>
    <b-sidebar
      id="sidebar-filter"
      v-model="sidebarShow"
      backdrop
      shadow
      backdrop-variant="dark"
      right
      ref="filterSidebar"
      aria-labelledby="sidebar-filter-title"
      no-header
      width="80vw"
    >
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter">Filter</b-col>
          <b-col class="text-right">
            <button class="btn-clear" @click.prevent="clearFilter">
              x <span class="text-under">Clear Filter</span>
            </button>
          </b-col>
        </b-row>
        <p class="font-weight-bold mt-3 mb-2 p-black">Search</p>
        <b-input-group>
          <b-form-input
            type="search"
            class="search-bar"
            placeholder="Search Name, Member ID, Tel., Email"
            @keyup.enter.prevent="submitFilter"
            v-model="filter.search"
          >
          </b-form-input>
        </b-input-group>
        <template>
          <div>
            <div>
              <InputSelect
                title="Status"
                name="type"
                class="mt-2"
                v-model="filter.redeemOrderStatus"
                v-bind:options="statusListOption"
                valueField="id"
                textField="name"
                @onDataChange="filter.redeemOrderStatus = $event"
              />
            </div>
            <div>
              <InputSelect
                title="Branch"
                name="type"
                class="mt-2"
                v-model="filter.branchId"
                v-bind:options="branchListOption"
                valueField="id"
                textField="name"
                @onDataChange="filter.branchId = $event"
              />
            </div>
          </div>
        </template>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light rounded-pill"
              @click.prevent="hide"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              @click.prevent="submitFilter"
              block
              variant="light"
              id="handle-search-filter"
              class="text-body rounded-pill btn-main"
            >
              Search
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
    <div v-show="!isLoading" class="p-3">
      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="reportList"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:head(redeem_order_id)="">
          <b-form-group class="cb-table">
            <b-form-checkbox
              v-model="selectAllProduct"
              @change="selectAllTable"
              :unchecked-value="0"
              :value="1"
            ></b-form-checkbox>
          </b-form-group>
        </template>
        <template v-slot:cell(telephone)="data">
          <div>{{ convertTelePhone(data.item.telephone) }}</div>
        </template>
        <template v-slot:cell(redeem_order_id)="data">
          <b-form-group class="cb-table">
            <b-form-checkbox
              :value="data.item.redeem_order_id"
              v-model="action.list_redeem_order"
              @change="selectProductTable"
            ></b-form-checkbox>
          </b-form-group>
        </template>
        <template v-slot:cell(name)="data">
          <div v-if="data.item.name">
            <router-link :to="'/report/customer/' + data.item.user_GUID">
              <b-button variant="link" class="name-link">
                {{ data.item.name }}
              </b-button>
            </router-link>
          </div>
          <div v-else>
            <p class="m-0">-</p>
          </div>
        </template>
        <template v-slot:cell(redeem_date)="data">
          <p class="m-0" v-if="data.item.redeem_date">
            <span>{{
              new Date(data.item.redeem_date) | moment($formatDate)
            }}</span>
          </p>
          <p v-else class="m-0">-</p>
        </template>
        <template v-slot:cell(branch_name)="data">
          <div v-if="pickUpType == 2">{{ address(data.item) }}</div>
          <div v-else-if="pickUpType == 3">-</div>
          <div v-else>{{ data.item.branch_name || "-" }}</div>
        </template>
        <template v-slot:cell(status_name)="data">
          <p class="m-0" v-if="data.item.status_name">
            <span v-if="data.item.status < 3" class="text-warning">{{
              data.item.status_name
            }}</span>
            <span v-else-if="data.item.status < 6" class="text-success">{{
              data.item.status_name
            }}</span>
            <span v-else class="text-danger">{{ data.item.status_name }}</span>
          </p>
          <p v-else class="m-0">-</p>
        </template>
        <template v-slot:cell(received_date)="data">
          <p
            class="m-0"
            v-if="
              data.item.received_date &&
              data.item.received_date != '0001-01-01T00:00:00'
            "
          >
            <span
              >{{ new Date(data.item.received_date) | moment($formatDate) }} ({{
                new Date(data.item.received_date) | moment($formatTime)
              }})</span
            >
          </p>
          <p v-else class="m-0">-</p>
        </template>
        <template v-slot:cell(note)="data">
          {{ data.item.note }}
          <font-awesome-icon
            icon="sticky-note"
            class="pointer"
            @click.prevent="getNote(data.item)"
          />
        </template>

        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col cols="12" md="6" class="form-inline justify-content-start">
          <p class="mb-0 p-gray">
            Showing {{ rows > 0 ? filter.start + 1 : 0 }} to
            {{
              filter.start + filter.length >= rows
                ? rows
                : filter.start + filter.length
            }}
            of {{ rows }} entries
          </p>
        </b-col>
        <b-col cols="12" md="6" class="form-inline justify-content-end">
          <div>
            <b-pagination
              v-model="filter.page"
              :total-rows="rows"
              :per-page="filter.length"
              @change="pagination"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              class="m-md-0"
            ></b-pagination>
          </div>
          <div class="ml-2">
            <b-form-select
              v-model="filter.length"
              @change="handlePerpage"
              :options="pageOptions"
            ></b-form-select>
          </div>
        </b-col>
      </b-row>
    </div>
    <ModalImportFile ref="modalImportFile" :id="id" />
    <ModalUpdateNote
      ref="modalUpdateNote"
      :form="filterNote"
      :v="$v"
      v-if="!loadingModalNote"
      @updateNote="updateNote"
    />
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
import OtherLoading from "@/components/loading/OtherLoading";
import ModalUpdateNote from "@/components/redeem/modal/ModalUpdateNote";
import ModalImportFile from "@/components/redeem/modal/ModalImportFile";
import { required } from "vuelidate/lib/validators";
export default {
  name: "RedeemReport",
  components: {
    InputSelect,
    OtherLoading,
    ModalUpdateNote,
    ModalImportFile,
  },
  props: {
    id: {
      type: Number,
      isRequired: true,
    },
    data: {
      type: Object,
      isRequired: true,
    },
  },
  validations: {
    filterNote: {
      note: { required },
    },
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      totalRedeem: 19954,
      received: 12780,
      waitForReceived: 9146,
      cancel: 258,
      sidebarShow: false,
      filter: {
        redeemId: parseInt(this.$route.params.id),
        page: 1,
        length: 10,
        start: 0,
        search: "",
        branchId: 0,
        onlyInRedeem: 1,
        redeemOrderStatus: 0,
        selectProductList: [],
        DeleteProductList: [],
      },
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      branchListOption: [{ id: 0, name: "All" }],
      statusListOption: [
        { id: 0, name: "All" },
        { id: 1, name: "รอจัดเตรียมสินค้า" },
        { id: 2, name: "กำลังจัดเตรียม" },
        { id: 3, name: "สินค้าพร้อมรับ" },
        { id: 4, name: "รับของโดยไม่ได้รับ OTP" },
        { id: 5, name: "รับของแล้ว" },
        { id: 6, name: "ยกเลิก" },
        { id: 7, name: "หมดอายุ" },
        { id: 8, name: "ส่งสำเร็จ" },
      ],
      fields: [
        {
          key: "redeem_order_id",
          label: "",
          thClass: "text-nowrap",
        },
        {
          key: "name",
          label: "Name-Surname",
          thClass: "text-nowrap",
        },
        {
          key: "member_id",
          label: "Member ID",
          thClass: "text-nowrap",
        },
        {
          key: "telephone",
          label: "Tel.",
          thClass: "text-nowrap",
        },
        {
          key: "email",
          label: "Email",
          thClass: "text-nowrap",
        },
        {
          key: "used_point",
          label: "Used Point",
          thClass: "text-nowrap",
        },
        {
          key: "redeem_date",
          label: "Redeem Date",
          thClass: "text-nowrap",
        },
        {
          key: "gift",
          label: "Gift",
          thClass: "text-nowrap",
        },
        {
          key: "branch_name",
          label: "Pick Up",
          thClass: "text-nowrap",
        },
        {
          key: "status_name",
          label: "Status",
          thClass: "text-nowrap",
        },
        {
          key: "received_date",
          label: "Date/Time Received",
          thClass: "text-nowrap",
        },
        {
          key: "note",
          label: "Note",
          thClass: "text-nowrap",
        },
      ],
      reportList: [],
      ListIdAll: [],
      ListAll: [],
      selectAllProduct: 0,
      selectItem: [],
      action: {
        list_redeem_order: [],
        to_status: 0,
        redeem_id: this.id,
      },
      actionDisabled: true,
      onlyAction: true,
      filterNote: {
        redeem_order_id: 0,
        note: null,
      },
      loadingModalNote: true,
      pickUpType: "",
    };
  },
  computed: {
    textStatusClass(status) {
      if (status < 3) {
        return "text-warning";
      } else if (status < 6) {
        return "text-success";
      } else {
        return "text-danger";
      }
    },
    exportDisabled() {
      return this.action.list_redeem_order.length > 0 ? false : true;
    },
  },
  created() {
    this.getBranchList();
  },
  methods: {
    async getRedeemOrderList() {
      this.isBusy = true;
      this.filter.start = (this.filter.page - 1) * this.filter.length;
      await this.$store.dispatch("getRedeemDetail", this.filter);
      const data = this.$store.state.redeem.redeemDetail;

      if (data.result == 1) {
        this.reportList = data.detail.data.redeemList;
        this.ListAll = data.detail.redeemListOrderId;
        this.ListIdAll = data.detail.redeemListOrderId.map((el) => el.id);
        this.rows = data.detail.redeemListTotal;
        this.data.totalRedeem = data.detail.data.redeemDetail.count_Redeemed;
        this.data.inProgress = data.detail.data.redeemDetail.in_Progress;
        this.data.success = data.detail.data.redeemDetail.success;
        this.data.cancelOrExpire =
          data.detail.data.redeemDetail.cancel_Or_Expire;
        this.pickUpType =
          this.$store.state.redeem.redeemDetail.detail.data.redeemDetail.pick_Up_Type_Id;
      } else {
        this.reportList = [];
        this.ListIdAll = [];
        this.rows = 0;
      }
      this.isBusy = false;
    },
    async getBranchList() {
      await this.$store.dispatch("getBranchList", null);
      const data = this.$store.state.redeem.branchList;
      if (data.result == 1) {
        this.branchListOption = this.branchListOption.concat(data.detail);
      }
    },
    async exportData() {
      this.isLoading = true;

      await this.$store.dispatch("exportByRedeemOrderId", {
        redeem_order_id: this.selectAllProduct
          ? []
          : this.action.list_redeem_order,
        is_all: this.selectAllProduct,
        redeem_id: this.$route.params.id,
      });
      const data = this.$store.state.redeem.exportRedeem;
      if (data.status == 200) {
        var fileURL = window.URL.createObjectURL(new Blob([data.data])); // change object.
        var fileLink = document.createElement("a");
        var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `RedeemOrderList-` + dateExcel + `.xlsx`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        alert("Export fail");
      }
      this.isLoading = false;
    },
    confirmAction(status) {
      this.action.to_status = status;
      this.$emit("confirmAction", null);
    },
    async changeStatus() {
      this.isLoading = true;
      await this.$store.dispatch("updateStatusRedeem", this.action);
      const data = this.$store.state.redeem.updateStatusRedeem;
      if (data.result == 1) {
        this.isLoading = false;
        this.selectAllProduct = 0;
        this.action.list_redeem_order = [];
        this.actionDisabled = true;
        this.onlyAction = true;
        await this.getRedeemOrderList();
      } else {
        alert(data.message);
      }
    },
    selectAllTable(value) {
      if (value) {
        this.action.list_redeem_order = this.ListIdAll;
      } else {
        this.action.list_redeem_order = [];
      }
      this.checkDisabledAction();
    },
    selectProductTable(value) {
      this.selectAllProduct = this.ListIdAll.length == value.length ? 1 : 0;
      this.checkDisabledAction();
      // กรองว่ามีสถานะ ยกเลิก ไหม
    },
    checkDisabledAction() {
      this.actionDisabled = false;
      this.onlyAction = false;

      if (this.action.list_redeem_order.length > 0) {
        this.action.list_redeem_order.forEach((e) => {
          this.ListAll.forEach((report) => {
            if (report.id == e) {
              if (report.status > 3) {
                this.actionDisabled = true;
              }
              if (report.status == 4) this.onlyAction = true;
            }
          });
        });
      } else {
        this.onlyAction = false;
        this.actionDisabled = false;
      }
    },
    pagination(page) {
      this.filter.page = page;
      this.getRedeemOrderList();
    },
    async handlePerpage(value) {
      this.filter.page = 1;
      this.filter.length = value;
      this.getRedeemOrderList();
    },
    async submitFilter() {
      this.filter.page = 1;
      await this.getRedeemOrderList();
      this.$refs.filterSidebar.hide();
    },
    hide() {
      this.$refs.filterSidebar.hide();
    },
    clearFilter() {
      this.filter = {
        redeemId: parseInt(this.$route.params.id),
        page: 1,
        length: 10,
        start: 0,
        search: "",
        branchId: 0,
        redeemOrderStatus: 0,
      };
    },
    async getNote(item) {
      this.loadingModalNote = true;
      await this.$store.dispatch("getNoteRedeem", item.redeem_order_id);
      const data = this.$store.state.redeem.stateNoteRedeem;
      if (data.result === 1) {
        this.loadingModalNote = false;
        this.filterNote.note = data.detail;
        this.filterNote.redeem_order_id = item.redeem_order_id;
        this.$nextTick().then(() => {
          this.$refs.modalUpdateNote.show();
        });
      } else {
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
    async updateNote(val) {
      await this.$store.dispatch("updateNoteRedeem", val);
      const data = this.$store.state.redeem.stateUpdateNoteRedeem;
      if (data.result === 1) {
        this.$swal(data.message, {
          icon: "success",
        });
        this.$nextTick().then(() => {
          this.$refs.modalUpdateNote.hide();
        });
      } else {
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
    importData() {
      this.$refs.modalImportFile.show();
    },
    address(item) {
      let address = `${item.home_address || ""} ${item.subdistrict || ""} ${
        item.district || ""
      } ${item.province || ""} ${item.zip_code || ""}`;
      return address.trim() ? address : "-";
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
label {
  font-weight: bold;
}
.pre-box {
  background: var(--theme-secondary-color) !important;
  color: var(--font-color) !important;
}

.cb-table {
  position: relative;
  margin-left: 15px;
  padding-top: 15px;
}
::v-deep .btn-secondary.dropdown-toggle {
  background-color: white;
  border-color: var(--primary-color);
}
.text-gray {
  color: gray;
}
</style>
